import { connect } from 'react-redux';
import { IRootState } from '../../redux/reducers-types';

import MobileMenu from './MobileMenu';
import { IMobileMenuStateProps } from './MobileMenu-types';

function mapStateToProps(state: IRootState): IMobileMenuStateProps {
    return {
        roles: state.auth.roles,
        featureToggles: state.featureToggles.toggles,
        tenantCode: state.auth.accountCode,
    };
}

export default connect(mapStateToProps)(MobileMenu);
