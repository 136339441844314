import { SUGGESTION } from '../../components/App/App-types';
import { IAuthReducer } from '../../components/Auth/Auth-types';

export const EVENT_ACTION_BULK_IGNORE = 'Bulk Ignore';
export const EVENT_ACTION_BULK_REPROCESS = 'Bulk Re-analyse';
export const EVENT_ACTION_BULK_SIGN = 'Bulk Sign';
export const EVENT_ACTION_MARK_AS_INTERSTING = 'Marked as Interesting';
export const EVENT_ACTION_DOWNLOAD = 'Download';
export const EVENT_ACTION_IGNORE = 'Ignore';
export const EVENT_ACTION_LOGOUT = 'Logout';
export const EVENT_ACTION_REPROCESS = 'Re-analyse';
export const EVENT_ACTION_RESOLVE = 'Resolve';
export const EVENT_ACTION_SHARE_INTERNALLY = 'Share Internally';
export const EVENT_ACTION_SIGN = 'Sign';
export const EVENT_ACTION_UNSIGN = 'Unsign';
export const EVENT_ACTION_SPECIFY_PARTIES = 'Specify Parties';
export const EVENT_ACTION_SUCCESSFUL_PROCESSING = 'Successful Processing';
export const EVENT_ACTION_UPGRADE_TO_ISSUE = 'Upgraded to Issue';
export const EVENT_ACTION_UPLOAD_NEGOTIATION = 'Upload Negotiation';
export const EVENT_ACTION_UPLOAD_REVISION = 'Upload Revision';
export const EVENT_ACTION_ADD_NEW_ISSUE = 'Add New Issue';
export const EVENT_ACTION_ADD_SELECTION_NEW_ISSUE =
    'Add selection as New Issue';
export const EVENT_ACTION_VIEW_NEXT_ISSUE = 'View Next Issue';
export const EVENT_ACTION_VIEW_PREVIOUS_ISSUE = 'View Previous Issue';
export const EVENT_ACTION_ISSUE_CARD_OPENED = 'Issue card opened';
export const EVENT_ACTION_ISSUE_STATUS_FILTER_SELECTED =
    'Issue status filter selected';
export const EVENT_ACTION_ISSUE_STATUS_FILTER_REMOVED =
    'Issue status filter removed';
export const EVENT_ACTION_ISSUE_SEVERITY_FILTER_SELECTED =
    'Issue severity filter selected';
export const EVENT_ACTION_ISSUE_SEVERITY_FILTER_REMOVED =
    'Issue severity filter removed';
export const EVENT_ACTION_ISSUE_CATEGORY_FILTER_SELECTED =
    'Issue category filter selected';
export const EVENT_ACTION_ISSUE_CATEGORY_FILTER_REMOVED =
    'Issue category filter removed';
export const EVENT_ACTION_ISSUE_PRESENT_DISPLAY_FILTER_SELECTED =
    'Display Issues Present';
export const EVENT_ACTION_ISSUE_NOT_DETECTED_DISPLAY_FILTER_SELECTED =
    'Display Issues Not Detected';
export const EVENT_ACTION_ISSUE_PRESENT_DISPLAY_FILTER_REMOVED =
    'Remove Issues Present Filter';
export const EVENT_ACTION_ISSUE_NOT_DETECTED_DISPLAY_FILTER_REMOVED =
    'Remove Issues Not Detected Filter';
export const EVENT_ACTION_FA_FILTER_SELECTED = 'Familiarity filter selected';
export const EVENT_ACTION_FA_FILTER_REMOVED = 'Familiarity filter removed';
export const EVENT_ACTION_THEME_FILTER_SELECTED = 'Theme filter selected';
export const EVENT_ACTION_THEME_FILTER_REMOVED = 'Theme filter removed';
export const EVENT_ACTION_ISSUE_SELECT_SAVED_VIEW = 'Select Saved View';
export const EVENT_ACTION_SET_PARTIES_CONFIRMATION_CONTINUE =
    'Set Parties Confirmation - Continue';
export const EVENT_ACTION_INITIATE_SET_PARTIES_FLOW =
    'Initiate Set Parties Workflow';
export const EVENT_ACTION_VIEW_CONTRACT = 'View Contract';
export const EVENT_ACTION_VIEW_ISSUES_REPORT = 'View Issues Report';
export const EVENT_ACTION_VIEW_ANALYTICS_REPORT = 'View Analytics Report';
export const EVENT_ACTION_SUGGESTION_COPIED = `Copy ${SUGGESTION}`;
export const EVENT_ACTION_DESCRIPTION_COPIED = 'Copy Description';
export const EVENT_ACTION_EXTERNAL_NOTES_COPIED = 'Copy External Comments';
export const EVENT_ACTION_EDIT_ISSUES_REPORT_COLUMN =
    'Edit Columns in Issues Report';
export const EVENT_ACTION_VIEW_LINKED_CLAUSE = 'View Linked Clause';
export const EVENT_ACTION_EXPORT_ISSUES_REPORT = 'Export Issues Report';
export const EVENT_ACTION_COPY_ISSUES_TABLE = 'Copy Issues Report Table';
export const EVENT_ACTION_COPY_ISSUES_REPORT_LINK =
    'Copy Link to Issues Report';
export const EVENT_ACTION_VIEW_EXISTING_ANALYTICS_REPORT =
    'View existing report [Contract Analytics]';
export const EVENT_ACTION_VIEW_EXISTING_ANALYTICS_REPORT_NEW =
    'View existing report [Reports]';
export const EVENT_ACTION_CREATE_NEW_ANALYTICS_REPORT =
    'Create new report [Contract Analytics]';
export const EVENT_ACTION_ANALYTICS_REPORT_PAGE_NAVIGATION =
    'Page navigation [Contract Analytics]';
export const EVENT_ACTION_ANALYTICS_REPORT_PAGE_NAVIGATION_NEW =
    'Data Grid Page navigation [Reports]';
export const EVENT_ACTION_ANALYTICS_REPORT_PREVIEW_LINKED_CLAUSES =
    'Preview linked clause in Analytics Report';
export const EVENT_ACTION_ANALYTICS_REPORT_PREVIEW_LINKED_CLAUSES_NEW =
    'Preview clauses [Reports]';
export const EVENT_ACTION_ANALYTICS_REPORT_VIEW_IN_CONTRACT =
    'View Issue In Contract [Contract Analytics]';
export const EVENT_ACTION_ANALYTICS_REPORT_VIEW_PARAGRAPH_IN_CONTRACT =
    'View Paragraph In Contract [Contract Analytics]';
export const EVENT_ACTION_ANALYTICS_REPORT_CANCEL_COLUMN_CHANGES =
    'Cancel column changes [Contract Analytics]';
export const EVENT_ACTION_ANALYTICS_REPORT_APPLY_COLUMN_CHANGES =
    'Apply column changes [Contract Analytics]';
export const EVENT_ACTION_ANALYTICS_REPORT_EDIT_COLUMNS =
    'Edit Columns button [Contract Analytics]';
export const EVENT_ACTION_ANALYTICS_REPORT_RENAME_REPORT =
    'Rename Report [Contract Analytics]';
export const EVENT_ACTION_ANALYTICS_REPORT_DELETE_REPORT =
    'Delete Report [Contract Analytics]';
export const EVENT_ACTION_ANALYTICS_REPORT_EXPORT_TO_CSV =
    'Export CSV [Contract Analytics]';
export const EVENT_ACTION_ANALYTICS_REPORT_EXPORT_TO_CSV_NEW =
    'Export CSV [Reports]';
export const EVENT_ACTION_ANALYTICS_REPORT_EXPORT_TO_EXCEL =
    'Export Excel [Reports]';
export const EVENT_ACTION_ANALYTICS_REPORT_SAVE_PREFERENCES =
    'Save report [Contract Analytics]';
export const EVENT_ACTION_REPORTS_APPLY_CHANGES =
    'Apply Report changes [Reports]';
export const EVENT_ACTION_REPORTS_CANCEL_EDIT_REPORT =
    'Cancel Edit Report [Reports]';
export const EVENT_ACTION_REPORTS_EDIT_REPORT = 'Edit Report button [Reports] ';
export const EVENT_ACTION_SWAPS_PARTIES = 'Swaps Parties';
export const EVENT_ACTION_CONFIRMS_PARTIES = 'Confirms Parties';
export const EVENT_ACTION_INITIATE_SET_PARTIES_WORKFLOW =
    'Initiate Set Parties Workflow';
export const EVENT_ACTION_SELECT_FA_IN_SP = 'Selects FA in SP dropdown';
export const EVENT_ACTION_SELECT_FA_IN_ISSUE_PANE =
    'Selects FA in Issue Pane dropdown';
export const EVENT_ACTION_FA_BAR_CLICK_IN_SP = 'Click FA bar in SP';
export const EVENT_ACTION_FA_BAR_CLICK_IN_ISSUE_PANE =
    'Click FA bar in Issue Pane';
export const EVENT_ACTION_FA_BANNER_NAVIGATION =
    'Uses navigation arrows in notification bar';
export const EVENT_ACTION_FA_SORT_IN_ISSUE_PANE =
    'Applies FA sort in Issue Pane';
export const EVENT_ACTION_INTELLIGENCE_TAB_IN_ISSUE_CARD =
    'Selects "Intelligence" tab in issue card';
export const EVENT_ACTION_SELECT_PARA_IN_INTELLIGENCE_TAB =
    'Selects a paragraph in "Intelligence" tab';
export const EVENT_ACTION_COPY_NEGOTIATION_LINK = 'Copy Link to Negotiation';

export const EVENT_KEY_ANALYTICS_REPORT_ID = 'Analytics Report ID';
export const EVENT_ACTION_FOCUS_CONTRACT_NAME =
    'Select Edit Contract Name [Secondary Navigation]';
export const EVENT_ACTION_BACK_BUTTON =
    'Select Back Button [Secondary Navigation]';
export const EVENT_ACTION_REVIEW_TAB =
    'Click on Review Tab [Secondary Navigation]';
export const EVENT_ACTION_VIEW_ALL_OWNERS =
    'View All Owners and Reviewers [Secondary Navigation]';
export const EVENT_ACTION_DOWNLOAD_CONTRACT_PRIMARY =
    'Click primary Download Button [Secondary Navigation]';
export const EVENT_ACTION_DOWNLOAD_CONTRACT_SECONDARY =
    'Download Contract using dropdown [Secondary Navigation]';
export const EVENT_ACTION_SHARE_CONTRACT_CLICK =
    'Click on Share Contract icon [Secondary Navigation]';
export const EVENT_ACTION_CONTEXT_MENU_CLICK =
    'Click on 3 dots menu icon [Secondary Navigation]';
export const EVENT_ACTION_DOCUMENT_VIEWER_MISSING_FONT =
    'Missing font in Document Viewer';
export const EVENT_ACTION_EXPORT_CONTRACT_REPORT =
    'Export Report [Contract Report]';
export const EVENT_ACTION_EDIT_LINKED_CLAUSES_TEMP =
    'Edit Linked Clauses in Issues Report (Temp)';
export const EVENT_ACTION_CLOSE_ALL_ISSUES = 'Close All Issues';

export const MP_NEGOTIATIONS = 'NEGOTIATIONS';
export const MP_SHARED = 'SHARED';

export const USER_TYPE_AUTHOR = 'author';
export const USER_TYPE_BILLING_USER = 'billing-user';
export const USER_TYPE_BUSINESS_USER = 'business-user';
export const USER_TYPE_LEGAL_ADMIN = 'administrator';
export const USER_TYPE_LEGAL_ADVISER = 'legal-adviser';
export const USER_TYPE_SUPPORT_COORDINATOR = 'support-coordinator';

export const USER_TYPE_CODES = {
    [USER_TYPE_AUTHOR]: 'auth',
    [USER_TYPE_BILLING_USER]: 'bill',
    [USER_TYPE_BUSINESS_USER]: 'biz',
    [USER_TYPE_LEGAL_ADMIN]: 'adm',
    [USER_TYPE_LEGAL_ADVISER]: 'adv',
    [USER_TYPE_SUPPORT_COORDINATOR]: 'supt',
};

export interface IAnalyticsService {
    formatUserRoles: (roles: string[]) => string;
    getAccountIdentifier: (authReducer: IAuthReducer) => string;
    setAnalyticsEnv: () => void;
    getCompanyIdentifier: (authReducer: IAuthReducer) => string;
    initialise: () => void;
    recordEvent: (action: string, value?: string | number) => void;
}

export interface IAnalyticsEnv {
    mpTrackingId: string;
    mpSharedTrackingId: string;
    pendoTrackingId: string;
}

export enum ComponentReference {
    ISSUE_CARD = 'Issue Card',
    ISSUE_PANE = 'Issue Pane',
    ISSUES_REPORT = 'Issues Report',
}
