import { UserRole } from '../Auth/Auth-types';
import { IFeatureToggle } from '../FeatureToggleProvider/FeatureToggleProvider-types';

export enum MenuGroup {
    ACCOUNT_ADMIN = 'Account Admin',
    PLAYBOOK_MANAGEMENT = 'Playbook Management',
    PARTY_SETTINGS = 'Party Settings',
}

export enum MenuItemType {
    LINK = 'LINK',
    DIVIDER = 'DIVIDER',
}

export interface IMenuListItem {
    group?: MenuGroup;
    icon?: JSX.Element;
    label?: string;
    link?: { target?: '_self' | '_blank' | '_parent' | '_top'; url: string };
    secondaryIcon?: JSX.Element;
    requiredRoles?: UserRole[];
    suffix?: JSX.Element;
    type?: MenuItemType;
}

export interface IMobileMenuState {
    open: boolean;
}

export interface IMobileMenuStateProps {
    roles: string[];
    featureToggles: IFeatureToggle[];
    tenantCode: string;
}

export interface IMobileMenuReducer {}

export interface IMobileMenuDispatchProps {}

export interface IMobileMenuProps
    extends IMobileMenuDispatchProps,
        IMobileMenuStateProps {}

export type IMobileMenuActionTypes = any;
