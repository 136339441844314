import { useEffect, useRef } from 'react';

import { ISummaryPropertiesProps } from './SummaryProperties-types';

import './SummaryProperties.scss';

import SummaryProperty from './SummaryProperty/SummaryProperty';
import { ISummaryProperty } from '../SummaryPane-types';
import LegacyHTMLParser from '../../../services/legacyHTMLParser';
import {
    ILexiblePropertyUpdateParams,
    NO_VALUE,
    YES_VALUE,
} from '../../../services/legacyHTMLParser-types';
import { syncContractData } from '../../Contract/Contract-helpers';
import { formatDate, isValidDate } from '../../App/App-helpers';
import {
    SummaryPanelTemplateIn,
    SummaryPanelTemplatePropertyIn,
} from '@thought-river/negotiations-common/dist/api/playbookManager';
import { NONE_IDENTIFIED_OPTION, ValueType } from '../../App/App-types';
import {
    BannerClauseType,
    BannerType,
} from '../../HeatmapPane/HeatmapPane-types';
import { useUpdateSummaryPanelTemplate } from '@thought-river/negotiations-common/dist/api/playbookManager';
import { debounce } from 'lodash';
import {
    DraggableListItem,
    VirtualizedDraggableList,
} from '@modules/common/components/DraggableList';
import { useIssuePaneContext } from '@modules/common/context/IssuePaneProvider';
import { toast } from 'react-toastify';

const SummaryProperties = ({
    highlightedText,
    issues,
    newPropertyCodes,
    onReprocess,
    onTemplateUpdatedSuccessfully,
    paragraphs,
    properties,
    selectedContractIntelligence,
    selectedPropertyCode,
    selectedTemplate,
    setHighlightedParagraphs,
    setHighlightedText,
    setBannerSettings,
    setSelectedContractIntelligence,
    setSelectedIssue,
    setSelectedParagraph,
    setSelectedPropertyCode,
    setSummaryProperties,
    toggleShowPropertyDetails,
    updateSummaryProperty,
}: ISummaryPropertiesProps) => {
    const { openIssuePane } = useIssuePaneContext();

    const propertiesEndRef = useRef<HTMLDivElement | null>(null);
    const { mutateAsync: updateTemplate } = useUpdateSummaryPanelTemplate({});

    useEffect(() => {
        if (newPropertyCodes.length) {
            setTimeout(() => {
                propertiesEndRef.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }, 0); //without timeout it executes this before new properties are rendered
        }
    }, [newPropertyCodes]);

    useEffect(
        () => () => {
            setBannerSettings(null!); // Fixme: null checks
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    //Update banner settings if properties change
    useEffect(() => {
        if (!selectedPropertyCode) {
            return;
        }

        const updatedSelectedProperty = properties.find(
            (p) => p.code === selectedPropertyCode
        );

        // If the current selected property is not a part of the new properties then return
        if (!updatedSelectedProperty) {
            return;
        }

        if (updatedSelectedProperty.reprocessRequired) {
            return;
        }

        setBannerSettings({
            type: BannerType.INFO,
            clauseType: updatedSelectedProperty.linkedParagraphIds.length
                ? BannerClauseType.PROPERTY_LINKED
                : BannerClauseType.PROPERTY_RELATED,
            items: updatedSelectedProperty.linkedParagraphIds.length
                ? updatedSelectedProperty.linkedParagraphIds
                : updatedSelectedProperty.relatedParagraphIds,
        });
    }, [properties, selectedPropertyCode, setBannerSettings]);

    const debouncedSyncContractData = useRef(
        debounce(() => {
            syncContractData();
        }, 5000)
    );

    const showIssueDetailsPane = (propertyCode: string) => {
        const issue = issues.find((i) => i.propertyCode === propertyCode);

        if (issue) {
            setSelectedIssue(issue);
            openIssuePane();
        }
    };

    const handlePropertiesReorder = async (
        reorderedItems: DraggableListItem[]
    ) => {
        if (selectedTemplate) {
            const propertiesPayload: SummaryPanelTemplatePropertyIn[] = [];

            const dfCodeOrderMap = new Map();
            reorderedItems.forEach((item, order) => {
                propertiesPayload.push({ dfcode: item.id, order });
                dfCodeOrderMap.set(item.id, order);
            });

            const reorderedProperties = properties.map((p) => ({
                ...p,
                order: dfCodeOrderMap.get(p.code),
            }));

            setSummaryProperties(reorderedProperties);

            const requestBody: SummaryPanelTemplateIn = {
                name: selectedTemplate.name,
                description: selectedTemplate.description,
                properties: propertiesPayload,
            };

            try {
                await updateTemplate(
                    {
                        body: requestBody,
                        pathParams: {
                            summaryPanelTemplateId:
                                selectedTemplate.id_str ?? '',
                        },
                    },
                    {
                        onSuccess: (template) => {
                            onTemplateUpdatedSuccessfully(template);
                        },
                    }
                );
            } catch {
                toast.error('Error updating properties order');
                console.log('Error saving template order');
            }
        }
    };

    const onPropertyClick = (property: ISummaryProperty) => {
        if (!property || selectedPropertyCode === property.code) {
            setBannerSettings(null!); // Fixme: null checks
            setSelectedPropertyCode(null!); // Fixme: null checks
            setSelectedParagraph(null);
            setHighlightedParagraphs([]);
            setHighlightedText('');
            return;
        }

        if (highlightedText) {
            setHighlightedText('');
        }

        setBannerSettings(null!); // Fixme: null checks
        setSelectedContractIntelligence({
            ...selectedContractIntelligence,
            highlightType: null!, // Fixme: null checks
        });
        setSelectedPropertyCode(property.code);

        const emptyPropertyValue = !property.value || property.value === '';
        const negativePropertyValue =
            property.value === 'No' ||
            property.value === NONE_IDENTIFIED_OPTION ||
            emptyPropertyValue;

        if (property.reprocessRequired) {
            setBannerSettings({
                type: BannerType.REPROCESS_REQUIRED,
                clauseType: null!, // Fixme: null checks
            });
        } else if (
            property.valueType === ValueType.DISCRETE ||
            property.valueType === ValueType.YES_NO_CAT
        ) {
            if (negativePropertyValue) {
                setBannerSettings({
                    type: BannerType.INFO,
                    clauseType: BannerClauseType.PROPERTY_RELATED,
                    items: property.relatedParagraphIds,
                });
            } else {
                setBannerSettings({
                    type: BannerType.INFO,
                    clauseType: BannerClauseType.PROPERTY_LINKED,
                    items: property.linkedParagraphIds,
                });
            }
        } else {
            if (emptyPropertyValue && !property.linkedParagraphIds.length) {
                setBannerSettings({
                    type: BannerType.INFO,
                    clauseType: BannerClauseType.PROPERTY_RELATED,
                    items: property.relatedParagraphIds,
                });
            } else if (
                !negativePropertyValue ||
                (negativePropertyValue &&
                    property.linkedParagraphIds.length !== 0)
            ) {
                setBannerSettings({
                    type: BannerType.INFO,
                    clauseType: BannerClauseType.PROPERTY_LINKED,
                    items: property.relatedParagraphIds,
                });
            } else {
                setBannerSettings({
                    type: BannerType.INFO,
                    clauseType: BannerClauseType.PROPERTY_RELATED,
                    items: property.relatedParagraphIds,
                });
            }
        }

        if (
            property.valueType === ValueType.DATE &&
            !isValidDate(formatDate(property.value))
        ) {
            setHighlightedText(property.value);
        }
    };

    const onChangeAnswer = (newValue: string, property: ISummaryProperty) => {
        const legacyHtmlParser = LegacyHTMLParser.getInstance();
        const updateParams: ILexiblePropertyUpdateParams[] = [];

        if (
            (property.valueType === ValueType.DISCRETE ||
                property.valueType === ValueType.YES_NO_CAT) &&
            property.value === 'No'
        ) {
            const paragraphToHighlight = paragraphs.find((paragraph) =>
                paragraph.themeIds.includes(property.theme.id)
            );

            setBannerSettings({
                type: BannerType.INFO,
                clauseType: BannerClauseType.PROPERTY_LINKED,
                items: property.linkedParagraphIds,
            });
            setSelectedParagraph(paragraphToHighlight || null!); // Fixme: null checks
            setHighlightedParagraphs(
                paragraphToHighlight ? [paragraphToHighlight] : []
            );

            const updatedProperty: ISummaryProperty = {
                ...property,
                value: newValue,
            };

            updateSummaryProperty(updatedProperty);

            // Set the paragraph index to -1 to force the backend to change the answer without a linked clause
            updateParams.push({
                dfCode: property.code,
                value: YES_VALUE,
                paragraphIndex: '-1',
            });
        } else if (
            (property.valueType === ValueType.DISCRETE ||
                property.valueType === ValueType.YES_NO_CAT) &&
            property.value === 'Yes'
        ) {
            const updatedProperty: ISummaryProperty = {
                ...property,
                value: newValue,
                linkedParagraphIds: [],
            };
            setBannerSettings({
                type: BannerType.WARNING,
                clauseType: BannerClauseType.PROPERTY_RELATED,
                items: property.relatedParagraphIds,
            });
            updateSummaryProperty(updatedProperty);
            updateParams.push({
                dfCode: property.code,
                value: NO_VALUE,
            });
        } else {
            const updatedProperty: ISummaryProperty = {
                ...property,
                value: newValue || NONE_IDENTIFIED_OPTION,
            };
            setBannerSettings({
                type: BannerType.INFO,
                clauseType: property.linkedParagraphIds.length
                    ? BannerClauseType.PROPERTY_LINKED
                    : BannerClauseType.PROPERTY_RELATED,
                items: property.linkedParagraphIds.length
                    ? property.linkedParagraphIds
                    : property.relatedParagraphIds,
            });
            updateSummaryProperty(updatedProperty);

            updateParams.push({
                dfCode: property.code,
                value: newValue,
            });
        }

        legacyHtmlParser.editLexibleProperties(updateParams).then(() => {
            debouncedSyncContractData.current();
        });
    };

    const propertiesList: DraggableListItem[] = properties
        .sort((propertyA, propertyB) => propertyA.order - propertyB.order)
        .map((property) => {
            const content = (
                <SummaryProperty
                    key={property.code}
                    property={property}
                    onChange={onChangeAnswer}
                    onClick={() => onPropertyClick(property)}
                    onDetailsIconClick={toggleShowPropertyDetails}
                    onSeverityBadgeClick={() =>
                        showIssueDetailsPane(property.code)
                    }
                    isSelected={property.code === selectedPropertyCode}
                    isNew={newPropertyCodes.includes(property.code)}
                    onReprocess={onReprocess}
                />
            );
            return {
                id: property.code,
                content,
                data: property,
            };
        });

    return (
        <div className="summary-properties">
            {properties.length ? (
                <VirtualizedDraggableList
                    items={propertiesList}
                    defaultItemHeight={70}
                    onReorder={handlePropertiesReorder}
                />
            ) : (
                <div className="properties-placeholder">
                    No properties found.
                </div>
            )}
            <div ref={propertiesEndRef} />
        </div>
    );
};

export default SummaryProperties;
